import React from 'react';
import { Link } from 'gatsby';

import SEO from '../components/SEO';

import TermsSection from '../components/CommonSections/TermsSection';

const PrivacyPage = () => (
  <>
    <SEO title="Mortgage Payment Prizedraw Terms and Conditions" />
    <TermsSection 
      title="Mortgage Payment Prizedraw Terms and Conditions"
      content={(<SecondSection />)}
    />
  </>
);

const SecondSection = () => (
    <>
      <h2>Mortgage Payment Prizedraw Terms and Conditions</h2>
      <p>
        Enter Sprive’s prize draw to be in with the chance of us paying your monthly mortgage payment next month.
      </p>
      <h4>If you are referring people to Sprive:</h4>
      <p>
        <li>You must refer at least 1 friend between 00:01 on 01/08/2023 and 23:59 on 31/08/2023.</li>
        <li>Each successful referral counts as an entry point. Ie. if you successfully refer 5 friends, that counts as 5 entry points. The more friends you refer, the more points you have into the prize-draw.</li>
        <li>A successful referral is when they have downloaded the app and set up a Direct Debit with Sprive.</li>
        <li>There is no referral cash bonus for this campaign.</li>
      </p>

      <h4>If you have been referred to Sprive:</h4>
      <p>
        <li>You will be entered into the prize draw if you successfully sign-up to Sprive with a referral code between 00:01 on 01/08/2023 and 23:59 on 31/08/2023.</li>
        <li>A successful referral is when they have downloaded the app and set up a Direct Debit with Sprive.</li>
        <li>There is no referral cash bonus for this campaign.</li>
      </p>

      <h4>For all people:</h4>
      <p>
        <li>We will randomly select 1 person and contact via the email address associated with your Sprive account by 4th September 2023.</li>
        <li>We will transfer your monthly mortgage payment, up to £1,500 to your linked bank account via bank transfer, or if you prefer we can credit to your Sprive account.</li>
      </p>

      <h4>You may not:</h4>
      <p>
        <p>
          (a) create duplicate account for the promotion;
        </p>
        <p>
          (b) use alternative contact information to use the promotion;
        </p>
        <p>
          (c) do anything that might damage Sprive’s brand, goodwill or reputation;
        </p>
      </p>

      <h4>What other legal information should I know?</h4>
      <p>
        Sprive reserves the right to limit the invite promotion if we suspect you did not use the promotion in good faith, or have breached any of the above terms. In cases of suspected fraud, further action may result in the suspension of your Sprive account, and your Bonus being withheld from being paid to you. If you believe you’re owed a Promotion Bonus that hasn’t been paid, please email us at <a href="mailto:hello@sprive.com" type="email">hello@sprive.com</a>. In most cases these issues can be quickly investigated and resolved.
      </p>
      <p>
        We may, at our sole discretion, suspend, terminate or change the terms and requirements of the Promotion at any time. These terms are published in English, and any translation is provided as a courtesy only. Participants in the Promotion cannot derive any rights from the translated version. The English language version of these terms shall apply and prevail and be conclusive and binding. The English version shall be used in all legal proceedings to the extent permitted by law.
      </p>

      <p>
        These Promotion Terms shall be exclusively governed by and construed in accordance with the laws of England and Wales. Any disputes arising out of or in connection with these terms shall exclusively be submitted to and dealt with by the competent court in England.
      </p>

      <p>
        In order to benefit from this Promotion, you must be a new customer to Sprive and have agreed to our General Terms and Conditions. Any additional information provided by you in the course of a referral will be treated in line with the Terms & Conditions and Data Privacy Policy.
      </p>
    </>
);

export default PrivacyPage;
